import { lazy } from "react";

interface routerItem {
  path: string;
  title: string;
  element: Function;
  children?: Array<{
    path: string;
    title: string;
    element?: Function;
  }>;
}

const routerArr: routerItem[] = [
  {
    path: '/',
    title: '首页',
    element: lazy(() => import('../views/index')),
  },
  {
    path: '/miniapp/blindbox',
    title: '',
    element: lazy(() => import('@/views/miniapp/blindbox')),
  },
  {
    path: '/miniapp/stake',
    title: '质押',
    element: lazy(() => import('../views/miniapp/stake')),
  },
  {
    path: '/miniapp/fission',
    title: '分享赚钱',
    element: lazy(() => import('../views/miniapp/fission')),
  },
  {
    path: '/miniapp/fingerprint',
    title: 'fp',
    element: lazy(() => import('../views/miniapp/fingerprint')),
  },
  {
    path: '/miniapp/QuestionFeedback',
    title: '问题反馈',
    element: lazy(() => import('../views/miniapp/QuestionFeedback')),
  },
  {
    path: '/miniapp/report',
    title: '投诉',
    element: lazy(() => import('../views/miniapp/report')),
  },
  {
    path: '/miniapp/luckybox',
    title: '幸运盲盒',
    element: lazy(() => import('../views/miniapp/luckybox')),
  },
  {
    path: '/miniapp/checkassist',
    title: '签到领好礼',
    element: lazy(() => import('../views/miniapp/checkassist')),
  },
  {
    path: '/miniapp/invitefriend',
    title: '邀请好友',
    element: lazy(() => import('../views/miniapp/invitefriend')),
  },
  {
    path: '/miniapp/exchange',
    title: '兑换FCC',
    element: lazy(() => import('../views/miniapp/exchange')),
  },
  {
    path: '/miniapp/exchangeNew',
    title: '币兑',
    element: lazy(() => import('../views/miniapp/exchangeNew')),
  },
  {
    path: '/miniapp/freebuy',
    title: '币买',
    element: lazy(() => import('../views/miniapp/freebuy')),
  },
  {
    path: '/miniapp/splayer/detail',
    title: '播放详情',
    element: lazy(() => import('../views/miniapp/splayer/Detail')),
  },
  {
    path: '/miniapp/exchangeGame',
    title: '币兑交易大赛',
    element: lazy(() => import('../views/miniapp/exchangeGame')),
  },
  {
    path: '/miniapp/freebuy/order/detail',
    title: '币买',
    element: lazy(() => import('../views/miniapp/freebuy/order/Detail')),
  },
  {
    path: '/miniapp/splayer',
    title: '速播',
    element: lazy(() => import('../views/miniapp/splayer')),
  },
  {
    path: '/miniapp/splayer/detail',
    title: '播放详情',
    element: lazy(() => import('../views/miniapp/splayer/Detail')),
  },
  {
    path: '/landing',
    title: '签到落地页',
    element: lazy(() => import('../views/landingPage')),
  },
  {
    // 群活动盲盒分享页
    path: '/luckybox',
    title: 'Lucky box',
    element: lazy(
      () => import('../views/miniapp/groupActivity/luckyBox/detail')
    ),
  },
  {
    path: '/miniapp/handbook',
    title: 'Freechat攻略指南',
    element: lazy(() => import('../views/miniapp/handbook')),
  },
  {
    path: '/download',
    title: 'Freechat下载页',
    element: lazy(() => import('../views/download')),
  },
  {
    path: '/miniapp/twitterearn',
    title: '广场发推，赚取奖励',
    element: lazy(() => import('../views/miniapp/twitterearn')),
  },
  {
    path: '/miniapp/groupActivity',
    title: '群活动',
    element: lazy(() => import('../views/miniapp/groupActivity/luckyBox')),
  },
  {
    path: '/miniapp/freechatData',
    title: 'Freechat Data',
    element: lazy(() => import('../views/miniapp/freechatData')),
  },
  // {
  //   path: 'advertising',
  //   title: '广告投放',
  //   element: lazy(() => import('../views/404')),
  // }
];

export default routerArr;
